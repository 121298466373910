.spinner {
  width: inherit;
  height: inherit;
  border: 8px solid #000;
  border-radius: 50%;
  border-top: 8px solid #aaa;
  animation-name: spinner-animation;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* rhombus theme style */

.spinner.rhombus {
  border: 8px solid #00d4ff;
  border-top: 8px solid #032125;
}

/* revvana theme style */

.spinner.revvana {
  border: 8px solid #000;
  border-top: 8px solid #44D62C;
}

/* samsara theme style */

.spinner.samsara {
  border: 8px solid #00263e;
  border-top: 8px solid #0585fc;
}

/* litmus theme style */

.spinner.litmus {
  border: 8px solid #3adb90;
  border-top: 8px solid #032125;
}
