.welcome-page {
  min-height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  padding: 1.5rem;
}

.welcome-content {
  max-width: 1000px;
  width: 90%;
  text-align: center;
  padding: 1.5rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.welcome-logo {
  height: 45px;
  margin-bottom: 1rem;
}

h1 {
  color: #333;
  margin-bottom: 0.5rem;
  font-size: 2rem;
}

p {
  color: #666;
  font-size: 1rem;
  margin-bottom: 1.25rem;
}

.features {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-top: 1rem;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.feature {
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  transition: transform 0.2s;
}

.feature:hover {
  transform: translateY(-5px);
}

.feature h3 {
  color: #5333b5;
  margin-bottom: 0.35rem;
  font-size: 1.05rem;
}

.feature p {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 0;
  line-height: 1.3;
}

/* Tablet */
@media (max-width: 900px) {
  .welcome-content {
    padding: 1.25rem;
  }

  .features {
    gap: 1rem;
  }

  h1 {
    font-size: 1.75rem;
  }

  p {
    font-size: 0.95rem;
  }
}

/* Mobile */
@media (max-width: 600px) {
  .welcome-page {
    padding: 1rem;
  }

  .welcome-content {
    padding: 1rem;
  }

  .features {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }

  .feature {
    padding: 0.75rem;
  }
}
