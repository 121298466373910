.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: aliceblue;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-form {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: 40%;
  max-width: 500px;
  padding: 40px;
  gap: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.login-page-logo {
  height: 50px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 1rem;
}

.popup-login-email {
  padding: 10px 15px;
  outline: none;
  border-radius: 10px;
  border: 1px solid #dadde9;
}

.popup-login-password {
  outline: none;
  padding: 10px 15px;
  outline: none;
  border-radius: 10px;
  border: 1px solid #dadde9;
}

.popup-login-email::placeholder,
.popup-login-password::placeholder {
  color: #44444433;
}

.popup-login-email:focus,
.popup-login-password:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.popup-login-email.error,
.popup-login-password.error {
  border-color: #dc3545;
}

.popup-login-btn {
  color: white;
  border: 1px solid #5333b5;
  width: 100px;
  border-radius: 56px;
  padding: 0.8rem 1.2rem;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  transition: all 200ms;
  text-transform: capitalize;
  border: 1px solid transparent;
  background-color: #5333b5;
  color: white;
  margin: auto;
  cursor: pointer;
}

.popup-login-btn:hover:not(:disabled) {
  background-color: #357abd;
}

.popup-login-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.popup-login-btn.loading {
  position: relative;
  color: transparent;
}

.popup-login-btn.loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top-color: white;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  text-align: center;
  margin-bottom: -10px;
  background-color: #fde8e8;
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid #fad7d7;
}

.field-error {
  color: #dc3545;
  font-size: 0.75rem;
  margin-top: 4px;
  position: absolute;
}

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}