.searchbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #003029;
  border-radius: 10px;
  padding: 5px 10px;
  gap: 10px;
  margin: 30px auto;
  width: 80%;
  box-sizing: border-box;
  border: 1px solid white;
  -webkit-box-shadow: -1px 2px 30px 0px #aaa;
  -moz-box-shadow: -1px 2px 30px 0px #aaa;
  box-shadow: -1px 2px 30px 0px #aaa;
  background-color: white;
}

.searchbar-input-field {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  font-size: 22px;
  width: 90%;
}

.search-icon {
  font-size: 22px;
  color: #000;
}

.searchbar input {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 18px;
  width: 100%;
}

.search-button {
  background-color: #5333b5;
  border-radius: 10px;
  border: none;
  height: inherit;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 22px;
  color: white;
  cursor: pointer;
}

.search-button.disabled {
  background-color: #ccc !important;
  cursor: not-allowed;
}

@media (max-width: 1500px) {
  .searchbar {
    width: 70%;
  }
}

@media (max-width: 765px) {
  .searchbar {
    width: 80%;
  }
}


