.bar {
  background-color: #1ea05533;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  font-family: Manrope;
  font-size: 14px;
}

.bar.error {
  background-color: #ff6f7133;
}

.message {
  display: flex;
  align-items: center;
  gap: 10px;
}

.bar-icon {
  color: #1ea055;
}

.bar-icon.error {
  color: #ff6f71;
  font-size: 18px;
}

.bar-close-icon {
  cursor: pointer;
  font-size: 18px;
  color: #1d1b20;
}
