.unauthorized-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  padding: 2rem;
  text-align: center;
}

.unauthorized-container h1 {
  color: #5333b5;
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

.unauthorized-container p {
  color: #666;
  margin-bottom: 2rem;
  font-size: 1.1rem;
}

.button-group {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.back-link {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background-color: #5333b5;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.back-link:hover {
  background-color: #442a96;
  color: white;
  text-decoration: none;
}

.logout-button {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 1rem;
}

.logout-button:hover {
  background-color: #c82333;
}
