.cta-slider-container-wrapper {
  background-color: #00000065;
  /* background-color: #a09e9e; */
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  width: 0;
  /* transform: translateX(100%);
  transition: transform 0.3s ease-in-out; */
  /* transition: w; */
}

.cta-slider-container-wrapper.show {
  /* transform: translateX(0%); */
  width: 100vw;
}

.cta-slider-container {
  background-color: #fff !important;
  width: 50vw;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  padding: 1.5rem;
  box-sizing: border-box;
  overflow-y: auto;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

.cta-slider-container.show {
  transform: translateX(0%);
}

.slider-heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: red; */
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
}

.slider-heading {
  margin: 0;
  padding: 0;
  color: var(#333);
  font-family: inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.slider-close-button {
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
}

.slider-hr {
  border: 0;
  border-bottom: 0.25px solid rgba(0, 0, 0, 0.1);
  margin: 1.5rem 0;
}

.cta-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  font-family: Inter, sans-serif;
  color: #333;
}

.cta-table th,
.cta-table td {
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  padding: 0.5rem;
}

.cta-table th {
  background-color: #f5f5f5;
  font-weight: 500;
}

.cta-table input {
  border: none;
  width: 100%;
  background-color: #efefef;
  height: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
}

.comment-textarea {
  width: 100%;
  min-height: 50px;
  resize: vertical;
  background-color: #efefef;
  font-family: Manrope;
  padding: 0.5rem;
  border-radius: 4px;
  border: none;
}

.status-bar-container {
  margin-top: 16px;
}
