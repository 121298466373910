.webless-footer {
  background: #5333b5;
  width: 100%;
  /* height:4; */
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;
  box-sizing: border-box;
  font-family: Manrope;
}

.webless-footer-left-section {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 100%;
  text-decoration: none;
}

.webless-footer-left-section li {
  list-style: none;
  color: var(--white-2-ww, #fff);
  /* Small */
  font-family: Manrope;
  font-size: 0.7875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 0.9625rem */
  cursor: pointer;
}

.link-tag {
  text-decoration: inherit;
  color: inherit;
}

.webless-footer-left-section hr {
  transform: rotate(90deg);
  width: 1rem;
}

.linkedin-icon {
  color: var(--white-2-ww, #fff);
  font-size: 1.5rem;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .webless-footer {
    padding: 0.9rem 1rem;
  }
}
