.privacy-policy {
  padding: 2.5rem 0rem;
  width: 65%;
  margin: auto;
  font-family: Arial, sans-serif;
  padding-top: 8rem;
}
.privacy-policy {
  font-family: Arial, sans-serif;
}

.privacy-policy h1 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.privacy-policy h2 {
  font-size: 1rem;
  margin-top: 30px;
  margin-bottom: 0.4rem;
  font-weight: 700;
}

.privacy-policy h3 {
  font-size: 20px;
  margin-top: 20px;
}

.privacy-policy p {
  font-size: 1rem;
  line-height: 1.6;
}

.privacy-policy ul,
.privacy-policy ol {
  margin-left: 20px;
  padding-left: 20px;
}

.privacy-policy li {
  margin: 10px 0;
  font-size: 1rem;
}
