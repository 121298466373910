.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #f5f5f5;
  height: fit-content;
  width: 100%;
  box-sizing: border-box;
  z-index: 100;
  background-color: #fff;
}

.navbar-left-container {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.nav-logo-container img {
  height: 30px;
}

.navbar-right-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.navbar-right-container-hamburger {
  display: none;
  color: #5333b5;
}

.navbar-right-container-buttons-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  color: #666;
  font-size: 1.2rem;
  transition: color 0.2s;
}

.icon-button:hover {
  color: #dc3545;
}

.logout-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.logout-button:hover {
  background-color: #c82333;
}

.hamburger-options {
  position: absolute;
  top: 3.5rem;
  right: 0;
  width: 100vw;
  padding: 1rem 0;
  height: fit-content;
  background-color:#fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  z-index: 10;
  font-family: Manrope;
  box-sizing: border-box;
}

.hamburger-link {
  color: #000;
  font-size: 1.5rem;
  text-decoration: none;
}

.menu-container {
  display: none;
  position: relative;
}

.menu-icon {
  font-size: 1.5rem;
  cursor: pointer;
}

.menu-options {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 200px;
  z-index: 1000;
}

.icon-button.mobile {
  width: 100%;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

@media only screen and (max-width: 900px) {
  .navbar-container {
    padding: 0.9rem 1rem;
  }

  .navbar-right-container-buttons-wrapper {
    display: none;
  }

  .navbar-right-container-hamburger {
    display: block;
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .navbar-right-container > .calendly-button,
  .navbar-right-container > .icon-button {
    display: none;
  }

  .menu-container {
    display: block;
  }

  .logout-button.mobile {
    width: 100%;
    justify-content: center;
  }
}
