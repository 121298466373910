.edit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
  align-items: flex-start;
  gap: 0.625rem;
  border: 1px solid #333;
  border-radius: 6.25rem;
  background: #fff;
  width: fit-content;
  color: #000;
  cursor: pointer;
}

.edit-button:hover {
  background-color: #f5f5f5;
}
