.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 9rem);
  text-align: center;
  padding: 2rem;
}

.not-found-container h1 {
  font-size: 6rem;
  margin: 0;
  color: #dc3545;
}

.not-found-container h2 {
  font-size: 2rem;
  margin: 1rem 0;
  color: #343a40;
}

.not-found-container p {
  font-size: 1.2rem;
  color: #6c757d;
  margin-bottom: 2rem;
}

.home-link {
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.home-link:hover {
  background-color: #0056b3;
}
