.button {
  display: flex;
  padding: 0.75rem 1.5rem;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 6.25rem;
  background: #5333b5;
  width: fit-content;
  color: #fff;
  font-weight: 600;
  font-family: manrope;
  cursor: pointer;
  border: none;
}

.button.disabled{
  background-color: #ccc;
  cursor: not-allowed;
}


.button:hover{
  background-color: #3f1d9d;
}


.button.disabled:hover{
  background-color: #ccc;
  cursor: not-allowed;
}
