.metrics-dashboard-container {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 9rem);
}

.loading-state,
.error-state {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.2rem;
  color: #666;
}

.error-state {
  color: #dc3545;
}

iframe {
  flex: 1;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: calc(100vh - 9rem);
}
