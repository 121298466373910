.search-tile {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 20px;
  box-sizing: border-box;
  text-decoration: none;
  transition: box-shadow 200ms, border-bottom 250ms;
  background-color: #fff;
}

.search-tile:hover {
  box-shadow: rgba(43, 43, 43, 0.7) 0px 5px 15px;
}

.tile-image {
  width: 100%;
  border-radius: 10px;
}

.tile-title {
  font-weight: 600;
  margin: 10px 0px;
}

.tile-desc {
  font-size: 14px;
}

.edit-button-wrapper{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
