.showmore-button {
  border: none;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  padding: 0;
  margin: 0;
  margin-left: 10px;
  display: inline;
  cursor: pointer;
}

.result-desc.demo{
  width: 100%;
  padding: 0;
}
