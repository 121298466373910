.tile-edit-slider-container-wrapper {
  background-color: #0000006a;
  /* background-color: #a09e9e; */
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  width: 0;
}

.tile-edit-slider-container-wrapper.show {
  width: 100vw;
}

.tile-edit-slider-container {
  background-color: #fff !important;
  width: 50vw;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  padding: 1.5rem;
  box-sizing: border-box;
  overflow-y: auto;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  scrollbar-width: none;
}

.tile-edit-slider-container.show {
  transform: translateX(0%);
}

.slider-heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: red; */
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
}

.slider-heading {
  margin: 0;
  padding: 0;
  color: var(#333);
  font-family: inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.slider-close-button {
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
}

.slider-hr {
  border: 0;
  border-bottom: 0.25px solid rgba(0, 0, 0, 0.1);
  margin: 1.5rem 0;
}

.collapsible-edit-section {
  background-color: #f5f5f5;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
}

.collapsible-edit-section-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: inter;
}

.collapsible-edit-hr {
  border: 0;
  border-bottom: 0.25px solid rgba(0, 0, 0, 0.1);
  margin: 0 0 1rem 0;
}

.edit-thumbnail-image {
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 120px;
  border-radius: 0.25rem;
}

.edit-thumbnail-link {
  width: 100%;
}

.cta-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  font-family: Inter, sans-serif;
  color: #333;
}

.cta-table th,
.cta-table td {
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  padding: 0.5rem;
}

.cta-table th {
  background-color: #f5f5f5;
  font-weight: 500;
}

.summary-table input {
  border: none;
  width: 100%;
  background-color: #efefef;
  height: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
}
