.homepage {
}

.top-container {
  padding: 40px 0px;
  margin: auto;
  width: 70%;
}

.suggested-question-container {
  margin: auto;
  width: 70%;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.summary-container-wapper {
  margin: auto;
  padding: 20px;
  border-radius: 0.625rem;
  background: #fff;
}

.summary-edit-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.result-desc {
  font-family: Inter, sans-serif;
  /* width: 70%; */
  padding: 0 10px;
  margin: auto;
}

.cta-container-wrapper {
  margin-top: 1rem;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 0.625rem;
}

.tiles-container {
  background-color: #fff;
  padding: 20px 10%;
}

.spinner-container-wrapper {
  height: 50px;
  width: 50px;
  margin: auto;
  padding-bottom: 20px;
}

.my-masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  gap: 20px;
}

/* cta slider */
